<template lang="pug">
textarea.textual-textarea(v-model="input" type="text" :placeholder="placeholder" ref="input")
</template>
<script>
export default {
  name: 'TextualTextArea',
  props: {
    value: String,
    placeholder: {
      type: String,
      default: '',
      optional: true
    }
  },
  data() {
    return {
      input: ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.input = value
        this.setHeight()
      }
    },
    input() {
      this.$emit('input', this.input)
      this.setHeight()
    }
  },
  mounted() {
    this.setHeight()
    window.addEventListener('resize', this.setHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setHeight)
  },
  methods: {
    setHeight() {
      const { input } = this.$refs
      if (input) {
        input.style.height = ''
        input.style.height = `${input.scrollHeight + 3}px`
      }
    }
  }
}
</script>

<style lang="sass" scoped>
textarea
  width: 100%
  border: none
  resize: none
  background: transparent
  min-height: 1rem
  font-size: 1rem
  border-bottom: 2px solid #444
  padding: 0.5rem
  height: 2.5rem
  &:focus
    outline: none
    border-bottom: 2px solid #43ae43
</style>
