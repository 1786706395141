<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title Choose Sharesight Portfolio
    b-button(icon-left="times" @click="$parent.close()")
  section.modal-card-body
    b-loading(:active="isLoading" :can-cancel="false" :is-full-page="false")
    b-message(type="is-danger" title="Error" v-if="error")
      .manual-reconnect.content(v-if="error === 'manual-reconnect'")
        p
          | The link between nutworth and your sharesight account has expired,
          | and needs to be set up again.
        p
          b-button.is-dark(@click="relink") Click here to continue
      span(v-else) {{ error }}
    b-message(type="is-warning" title="No Portfolios Found" v-if="!error && !isLoading && !portfolios.length")
      | We weren't able to find any portfolios in your Sharesight account.
    .portfolios(v-for="portfolioOption in portfolios" :key="portfolioOption.id")
      b-radio(:value="portfolio.id" @input="setPortfolio(portfolioOption)" :native-value="portfolioOption.id")
        | {{ portfolioOption.name }}
  footer.modal-card-foot
    b-button.is-primary(@click="done" :disabled="isLoading") Done

</template>
<script>
import sharesight from '@/misc/sharesight'

export default {
  data() {
    return {
      isLoading: true,
      portfolio: { id: null },
      portfolios: [],
      error: null
    }
  },
  mounted() {
    this.getPortfolios()
  },
  methods: {
    done() {
      const { portfolio } = this
      this.$emit('choose', portfolio.id === null ? null : portfolio)
    },
    relink() {
      this.isLoading = true
      this.error = null
      sharesight
        .connect()
        .then(() => {
          this.getPortfolios()
        })
        .catch(error => {
          this.isLoading = false
          this.error = error.message
        })
    },
    setPortfolio(portfolio) {
      this.portfolio = portfolio
    },
    getPortfolios() {
      this.isLoading = true
      this.error = null
      sharesight
        .getPortfolios()
        .then(portfolios => {
          this.isLoading = false
          this.portfolios = portfolios
          if (portfolios.length) {
            this.portfolio = portfolios[0]
          } else {
            this.portfolio = { id: null }
          }
        })
        .catch(error => {
          this.isLoading = false
          this.error = error.message
        })
    }
  }
}
</script>
