<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title Edit {{typeLabel}}
    b-button(icon-left="times" @click="$parent.close()")
  section.modal-card-body
    .field
      label.label Type
      .control.is-clearfix
        multi-select(:value="selectedType" :options="typeClasses" @input="setType" label="name" track-by="id" :placeholder="typeLabel + ' type'")
      p.help Used to group together similar holdings in charts
    b-field(:label="typeLabel + ' Name'")
      b-input(custom-class='fix-input-size' required v-model="form.name")
    b-field(label="Offset Account For" v-if="canHaveLoanAccount")
      b-select(v-model="form.offsetLoanId" placeholder="None")
        option(:value='null') None
        option(v-for="account in potentialLoanAccounts" :value="account.id") {{ account.name }}
    b-field(label="Offset Account(s)" v-if="canHaveOffsetAccount")
      multi-select(:value="chosenOffsets" @input="updateOffsetIds" :multiple="true" :options="potentialOffsetAccounts" label="name" track-by="id" placeholder="Link offset(s)")
    b-field(:label="'Custom ' + typeLabel + ' Type'" v-if="form.type == 'custom'" message="Used to group your custom holdings in charts")
      b-input(type="text" v-model="form.customName" required placeholder="Custom name..." ref='customNameField')
    b-message(title="Error" type="is-warning" aria-close-label="Close" v-if="errorMessage") {{ errorMessage }}
  footer.modal-card-foot
    b-button.is-danger(v-if="!hasEntries" @click="remove" :loading='isRemoving' :disabled="isSaving") Delete
    .spacer
    b-button.is-primary(:disabled="isInvalid || isRemoving" title="Save" @click="save" :loading='isSaving') Save
</template>
<script>
import CategoryEditor from '@/components/CategoryEditor'
import NewAssetDialog from '@/components/NewAssetDialog'
import NewLiabilityDialog from '@/components/NewLiabilityDialog'
import categoryEdit from '@/mixins/categoryEdit'
import offsetLinker from '@/mixins/offsetLinker'

export default {
  components: {
    CategoryEditor,
    NewAssetDialog,
    NewLiabilityDialog
  },
  mixins: [categoryEdit, offsetLinker],
  props: {
    category: Object
  },
  data() {
    return {
      errorMessage: null,
      form: {},
      isRemoving: false,
      isSaving: false
    }
  },
  methods: {
    async save() {
      if (this.isSaving || this.isRemoving) return
      if (!this.isDirty) {
        this.$parent.close()
        return
      }
      this.isSaving = true
      this.errorMessage = null
      try {
        await this.saveCategory(this.form)
        this.$buefy.toast.open('Category updated.')
        this.$parent.close()
      } catch (error) {
        this.errorMessage = error.message
      }
      this.isSaving = false
    },
    async remove() {
      if (this.isSaving || this.isRemoving) return
      this.isRemoving = true
      this.errorMessage = null
      try {
        this.$emit('ondelete')
        await this.deleteCategory(this.category.id)
        this.$buefy.toast.open('Category removed.')
        this.$parent.close()
      } catch (error) {
        this.errorMessage = error.message
        this.isRemoving = false
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.modal-card, .modal-card-body
  overflow: visible
.message
  width: 100%
.modal-card-foot
  display: flex
.spacer
  flex-grow: 1
</style>
