<template lang="pug">
.edit-entry-screen.maybe-mobile-section
  .heading-area
    h1#entry-title Edit Entry
    router-link.button(:to="{name: 'entries'}")
      b-icon.back-icon(icon="arrow-left")
      span.button-text Cancel
    b-dropdown.entry-menu(position='is-bottom-left')
      b-button.menu-trigger(slot="trigger")
        b-icon(icon='cog')
      b-dropdown-item.has-text-danger(aria-role="listitem" @click="removeEntry") Delete Entry
    b-button.is-primary.save-button-top(@click="$refs.form.saveEntry()" :loading="isSaving" :disabled="saveDisabled") Save
  b-loading(:is-full-page="true" :active="initializing" :can-cancel="false")
  .content-area(v-if="!initializing")
    b-message(title="Error" type="is-warning" aria-close-label="Close" v-if="errorMessage") {{ errorMessage }}
    entry-form(mode="edit" :entry="entry" ref='form' @onsave="saveEntry" :is-saving="isSaving" @cansave="saveDisabled = !$event")
</template>
<script>
import { mapState, mapActions } from 'vuex'
import EntryForm from '@/components/EntryForm'

export default {
  components: {
    EntryForm
  },
  data() {
    return {
      isSaving: false,
      isDeleting: false,
      errorMessage: null,
      tooManyCategories: false,
      saveDisabled: false
    }
  },
  computed: {
    ...mapState('categories', ['loadingCategories']),
    ...mapState('currencies', ['loadingCurrencies']),
    ...mapState('entries', ['loadingEntries']),
    initializing() {
      return (
        this.loadingCategories || this.loadingCurrencies || this.loadingEntries
      )
    },
    entry() {
      return this.$store.getters['entries/getEntryById'](
        this.$route.params.entryId
      )
    }
  },
  methods: {
    ...mapActions('entries', ['updateEntry', 'deleteEntry']),
    saveEntry(entryForm) {
      if (this.isSaving) {
        return
      }
      if (this.tooManyCategories) {
        this.$buefy.toast.open('Please upgrade your plan to continue.')
        return
      }
      this.isSaving = true
      this.errorMessage = null
      this.updateEntry(entryForm)
        .then(() => {
          this.$router.push({ name: 'entries', query: { date: this.entry.id } })
          this.$buefy.toast.open('Entry updated!')
          this.isSaving = false
        })
        .catch(error => {
          this.isSaving = false
          this.errorMessage = error.message
        })
    },
    removeEntry() {
      this.isDeleting = true
      this.errorMessage = null
      this.deleteEntry(this.entry.id)
        .then(() => {
          this.$router.push({ name: 'entries' })
          this.$buefy.toast.open('Entry removed!')
          this.isDeleting = false
        })
        .catch(error => {
          this.isDeleting = false
          this.errorMessage = error.message
        })
    },
    checkCategories(entryForm) {
      const totalCategories =
        entryForm.assets.length + entryForm.liabilities.length
      this.tooManyCategories =
        this.isFreePlan &&
        this.$store.getters['categories/isOverLimit'](totalCategories)
    }
  },
  metaInfo: {
    title: 'Edit Entry'
  }
}
</script>
<style lang="sass" scoped>
.maybe-mobile-section
  display: flex
  flex-direction: column
  flex-grow: 1
  background: #fafafa
  padding: 0.5rem
.heading-area
  display: flex
  align-items: center
  margin-bottom: 1.5rem
.content-area
  flex-grow: 1
#entry-title
  order: 2
  text-align: center
  flex-grow: 1
  font-weight: bold
  font-size: 1.1rem
.entry-menu
  order: 3
.button-text
  display: none
  order: 1
.button
  display: flex
  align-items: center
  justify-content: center
  height: 2.5rem
  width: 2.5rem
  .back-icon
    margin: 0 !important
.save-button-top
  display: none
@media screen and (min-width: 769px)
  .maybe-mobile-section
    align-items: center
  .heading-area, .content-area
    width: 100%
    max-width: 1300px
  .button
    width: 5rem
    order: 2
  .button-text
    display: block
  .back-icon
    display: none
  .heading-area
    margin-top: 1.5rem
    padding: 0 0.5rem 0 0.75rem
  #entry-title
    font-size: 1.4rem
    font-weight: normal
    order: 1
    text-align: left
  .save-button-top
    display: block
    order: 3
    margin-left: 0.75rem
  .button.menu-trigger
    width: 2.5rem
    margin-left: 0.75rem
  .entry-menu
    order: 4
</style>
