<template lang="pug">
.asset-input(v-if="category")
  .asset-heading
    .label {{ category.name }}
    .settings
      b-button.trigger(v-if="importing" :loading="true" :tabindex="-1" icon-left="cog")
      b-dropdown(aria-role="list" position="is-bottom-left" v-if="!importing")
        b-button.trigger(slot="trigger" :tabindex="-1" icon-left="cog")
        b-dropdown-item(v-if="hasSubunits" @click="editSubunit")
          span Change unit
        b-dropdown-item(@click="editCategory")
          span Edit Category
        b-dropdown-item(v-if="showSharesightConnect" @click="initSharesightImport")
          span Import from Sharesight
        b-dropdown-item(aria-role="listitem" @click="removeAsset")
          span.has-text-danger Remove
  .input-fields
    .field.has-addons
      .control.input-control
        currency-input.input.currency-input(:value="amount" :currency="currencyOption" :precision="currencyPrecision" @input="updateAmount" ref='input' :disabled="importing")
      .control.currency-control
        currency-select(:value="chosenCurrency" @input="updateCurrency" placeholder="" prefix="" :tabindex="-1")
    b-field.custom-rate-input(:label="customRateLabel" v-if="customRate")
      b-input(custom-class='custom-rate-field' type="number" min="0.00000001" :value="baseRate" @input="updateBaseRate" required step='0.00000001')
  p.autofill(v-if="importing") Importing...
  p.autofill(v-if="(category.integration || lastAmount) && !importing")
    span.last-value-clicker(v-if="!category.integration && lastAmount")
      | Last value:&nbsp;
      a.autofill-clicker(@click="updateAmount(lastAmount)") {{ lastAmount | currencyFormatter(lastCurrency) }}
    span.sharesight-import(v-if="isIntegrated('sharesight')")
      a(@click="importSharesight()" v-if="!hasImported && !showSharesightReconnectLink") Click to load from Sharesight
      a.has-text-danger(@click="reconnectSharesight()" v-if="showSharesightReconnectLink")
        | You need to reconnect. Click here to continue
      span(v-if="hasImported") Imported successfully!
  a.exchange-rate(v-if="showRates && formattedExchangeRate" @click="copyExchangeRate()")
    | {{ formattedExchangeRate }}
    b-icon(icon='clipboard')
  b-modal(:active.sync="showSharesightDialog" has-modal-card :can-cancel="['escape']" :trap-focus="true" v-if="showSharesightDialog")
    sharesight-portfolio-picker(@choose="linkCategoryToPortfolio")
  b-modal(:active.sync="showCategoryEditor" has-modal-card :can-cancel="['escape']" :trap-focus="true" v-if="showCategoryEditor")
    category-edit-dialog(:category="category" @ondelete="removeAsset")
  b-modal(:active.sync="showSubunitEditor" has-modal-card :can-cancel="['escape']" :trap-focus="true" v-if="showSubunitEditor")
    subunit-edit-dialog(:currency="currency" :value="chosenSubunit.id" :amount="amount" @onchange="updateSubunit" :show-convert="showSubunitConvert")
</template>
<script>
import { get } from 'lodash'
import { mapState, mapActions, mapGetters } from 'vuex'
import CurrencySelect from '@/components/CurrencySelect'
import currencyFormatter from '@/misc/currencyFormatter'
import subunits from '@/mixins/subunits'
import sharesight from '@/misc/sharesight'
import { formatNumber } from '@/misc/filters'
import { currencyWithSubunit } from '@/misc/helpers'
import SharesightPortfolioPicker from '@/components/integrations/SharesightPortfolioPicker'
import CategoryEditDialog from '@/components/CategoryEditDialog'
import SubunitEditDialog from '@/components/SubunitEditDialog'

export default {
  components: {
    CurrencySelect,
    SharesightPortfolioPicker,
    CategoryEditDialog,
    SubunitEditDialog
  },
  filters: {
    currencyFormatter
  },
  mixins: [subunits],
  props: {
    amount: Number,
    baseRate: Number,
    categoryId: String,
    currency: String,
    subunit: String,
    customRate: Boolean,
    lastAmount: Number,
    lastCurrency: String,
    date: Date
  },
  data() {
    return {
      showSharesightDialog: false,
      showSharesightReconnectLink: false,
      importing: false,
      hasImported: false,
      showCategoryEditor: false,
      showSubunitEditor: false,
      showSubunitConvert: false
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('currencies', ['activeCurrency']),
    ...mapGetters('authentication', ['showExchangeRate']),
    ...mapGetters('currencies', [
      'defaultSubunit',
      'activeCurrencyWithSubunit',
      'activeSubunit'
    ]),
    showSharesightConnect() {
      return sharesight.enabled && sharesight.applicable(this.category)
    },
    category() {
      return this.$store.getters['categories/getCategoryById'](this.categoryId)
    },
    chosenCurrency() {
      return this.$store.getters['currencies/getCurrencyById'](this.currency)
    },
    customRateLabel() {
      return `${this.currency}:USD Rate`
    },
    showRates() {
      const {
        currency,
        subunit,
        baseRate,
        activeCurrencyWithSubunit,
        showExchangeRate
      } = this
      if (!showExchangeRate) {
        return false
      }
      if (!(baseRate && activeCurrencyWithSubunit && currency)) {
        return false
      }
      if (
        currencyWithSubunit(currency, subunit) === activeCurrencyWithSubunit
      ) {
        return false
      }
      return true
    },
    formattedExchangeRate() {
      const {
        exchangeRate,
        baseRate,
        currency,
        subunit,
        activeCurrency,
        activeSubunit
      } = this
      const rate = formatNumber(exchangeRate / baseRate, {
        maxSF: 8,
        maxPrecision: 8
      })
      return `
      1 ${subunit || ''} ${currency}
      = ${rate}${activeSubunit || ''} ${activeCurrency.id}
      `
    }
  },
  asyncComputed: {
    exchangeRate() {
      const { activeCurrencyWithSubunit, date } = this
      if (!activeCurrencyWithSubunit) {
        return null
      }
      return this.getExchangeRateOn({
        currency: activeCurrencyWithSubunit,
        date
      })
    }
  },
  watch: {
    date() {
      this.hasImported = false
    }
  },
  methods: {
    ...mapActions('categories', ['linkToSharesight']),
    ...mapActions('currencies', ['getExchangeRateOn']),
    copyExchangeRate() {
      const { exchangeRate, baseRate } = this
      const rate = formatNumber(exchangeRate / baseRate, {
        maxSF: 8,
        maxPrecision: 8
      })
      navigator.clipboard.writeText(rate).then(
        () => {
          this.$buefy.toast.open(`Copied to clipboard`)
        },
        () => {
          this.$buefy.toast.open(`Could not copy to clipboard`)
        }
      )
    },
    isIntegrated(type) {
      if (type === 'sharesight' && !sharesight.enabled) {
        return false
      }
      return (
        this.category &&
        this.category.integration &&
        this.category.integration.type === type
      )
    },
    updateAmount(amount) {
      this.$emit('update:amount', amount)
    },
    updateCurrency(currency) {
      // For :sync
      this.$emit('update:currency', currency.id)
      const { subunit } = this
      let shouldShowPicker

      // If the currency has subunits
      if (currency.subunits) {
        shouldShowPicker = true
        // If the currently set subunit is valid
        if (subunit in currency.subunits) {
          // Reuse it
          shouldShowPicker = false
        } else {
          // Set to default or null
          const { id } = this.defaultSubunit(currency.id) || { id: null }
          this.$emit('update:subunit', id)
        }
      } else if (subunit) {
        // Otherwise clear subunit if set
        this.$emit('update:subunit', null)
      }

      // To notify parent to adjust exchange rate
      this.$emit('currency-updated')

      // Get them to choose a subunit when they change to a currency with subunits
      // Wrap in a nextTick so all the getters have recomputed already
      if (shouldShowPicker) {
        this.$nextTick(() => {
          this.showSubunitConvert = false
          this.showSubunitEditor = true
        })
      }
    },
    updateSubunit({ subunit, convert }) {
      // To notify parent to adjust exchange rate
      this.$emit('update:subunit', subunit)
      this.$emit('currency-updated')
      // Recalculate amount if chosen
      if (convert) {
        const { amount, currency, chosenSubunit = { id: null } } = this
        const baseAmount = this.$store.getters['currencies/convertToBase'](
          amount,
          currency,
          chosenSubunit.id
        )
        const newAmount = this.$store.getters['currencies/convert'](
          baseAmount,
          currency,
          subunit
        )
        // Needs to happen next tick so that the editor has the new currency
        // config and doesn't mess up the rounding
        this.$nextTick(() => {
          this.$emit('update:amount', newAmount)
        })
      } else {
        this.$nextTick(() => this.focus())
      }
    },
    updateBaseRate(rate) {
      this.$emit('update:base-rate', rate)
    },
    removeAsset() {
      this.$emit('remove')
    },
    focus() {
      // Bit of a hack as there is no exposed focus method
      try {
        this.$refs.input.$vnode.elm.focus()
      } catch (error) {} //eslint-disable-line
    },
    async initSharesightImport() {
      const integration =
        this.user.integrations && this.user.integrations.sharesight
      if (!integration || integration.expired) {
        try {
          await sharesight.connect()
        } catch {
          return
        }
      }
      this.showSharesightDialog = true
    },
    linkCategoryToPortfolio(portfolio) {
      this.showSharesightDialog = false
      this.linkToSharesight({
        category: this.category,
        portfolio
      })
      this.importSharesight(portfolio.id)
    },
    importSharesight(portfolioId = this.category.integration.portfolioId) {
      this.importing = true
      sharesight
        .getPortfolio(portfolioId, this.date)
        .then(({ value }) => {
          this.importing = false
          this.hasImported = true
          const currency = get(
            this,
            'category.integration.currency',
            this.currency
          )
          const currencyObject = this.$store.getters[
            'currencies/getCurrencyById'
          ](currency)
          if (currencyObject) {
            this.updateAmount(value)
            this.updateCurrency(currencyObject)
          } else {
            this.updateAmount(value)
            this.$buefy.toast.open(`Unknown portfolio currency ${currency}`)
          }
        })
        .catch(error => {
          this.importing = false
          switch (error.message) {
            case 'manual-reconnect':
              this.showSharesightReconnectLink = true
              break
            case 'not-found':
              this.showSharesightDialog = true
              break
            default:
              this.$buefy.toast.open(`Error: ${error.message}`)
          }
        })
    },
    async reconnectSharesight() {
      this.importing = true
      try {
        await sharesight.connect()
        this.showSharesightReconnectLink = false
        this.importing = false
      } catch {
        this.$buefy.toast.open(`Could not reconnect to Sharesight.`)
        this.importing = false
        return
      }
      this.importSharesight()
    },
    editCategory() {
      this.showCategoryEditor = true
    },
    editSubunit() {
      this.showSubunitConvert = true
      this.showSubunitEditor = true
    }
  }
}
</script>

<style scoped lang="sass">
$currency-select-width: 70px
.asset-heading
  display: flex
  align-items: center
  .label
    flex-grow: 1
  .settings
    margin-bottom: 0.5em
    width: 1.5rem
    height: 1.5rem
  .dropdown, .trigger
    width: 1.5rem
    height: 1.5rem
  .trigger
    padding: 0
    margin: 0
    color: #444
    border: none
    ::v-deep svg
      width: 1.25rem
      height: 1.25rem
.autofill
  font-size: 0.9rem
.exchange-rate
  font-size: 0.75rem
  color: #555
  display: flex
  justify-content: flex-start
  align-items: flex-end
  .icon
    color: #595
    position: relative
    top: 1px
.input-fields
  margin-bottom: 0.5rem
  flex-grow: 1
.input-control
  flex-grow: 1
.dropdown
  width: 1.5em
  height: 1.5em
.currency-control
  width: $currency-select-width
  ::v-deep
    .multiselect__single
      margin-left: 0
      padding-left: 0
    .option-id
      white-space: nowrap
      margin-left: 0
      display: inline-block
      width: 100%
      text-align: center
    .multiselect__select
      display: none
    .multiselect__single strong
      color: #555
      font-size: 0.9rem
    .multiselect__tags
      border-top-left-radius: 0
      border-bottom-left-radius: 0
      border-color: #dbdbdb
      width: $currency-select-width
      max-width: $currency-select-width
      cursor: pointer
      padding-right: 8px
    .multiselect__content-wrapper
      width: 300px
      border-radius: 5px
      border-top-right-radius: 0
      right: 0
</style>
