<template lang="pug">
.add-holding
  .field
    .control
      b-select(v-model="selectValue" :placeholder="placeholder" :expanded="true")
        optgroup(v-if="existingOptions.length" label="Previously created")
          option(v-for="category in existingOptions" :value="{ type: 'existing', id: category.id }")
            | {{ category.name }}
        component(:is="existingOptions.length ? 'optgroup' : 'fragment'" label="Create new")
          option(v-for="category in newOptions" :value="{ type: 'new', id: category.id }")
            | {{ category.name }}
    p.help.is-primary.prompt-hint(v-if="atLimit || tooManyCategories" @click="promptForUpgrade")
      b-icon(icon="exclamation-circle")
      | Plan {{ atLimit ? 'holding' : 'category' }} limit reached
  b-modal(:active.sync="showUpgradePrompt" :can-cancel="['escape']" :trap-focus="true" v-if="showUpgradePrompt" has-modal-card)
    .modal-card
      header.modal-card-head
        p.modal-card-title Limit reached
        b-button(icon-left="times" @click="showUpgradePrompt = false")
      section.modal-card-body
        p.content
          | You've reached the limit of 8 holdings for our starter
          | plan. Thanks for trying us out! To continue adding holdings, you'll
          | need to upgrade to one of our &nbsp;
          router-link(:to="{name: 'billing_plan'}") Pro plans
          |. These all come with unlimited
          | holdings.
      footer.modal-card-foot
        router-link.button.is-primary(:to="{name: 'billing_plan'}") Upgrade
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    placeholder: String,
    newOptions: Array,
    existingOptions: Array,
    atLimit: Boolean
  },
  data() {
    return {
      selectValue: null,
      showUpgradePrompt: false
    }
  },
  computed: {
    ...mapGetters('categories', ['atFreePlanLimit']),
    ...mapGetters('authentication', ['isFreePlan']),
    tooManyCategories() {
      const { isFreePlan, atFreePlanLimit } = this
      return isFreePlan && atFreePlanLimit
    }
  },
  watch: {
    selectValue(value) {
      if (!value) {
        return
      }
      this.$nextTick(() => {
        this.selectValue = null
      })
      if (value.type === 'new' && this.tooManyCategories) {
        this.promptForUpgrade()
        return
      }
      if (value.type === 'existing' && this.atLimit) {
        this.promptForUpgrade()
        return
      }
      this.$emit('on-add', value)
    }
  },
  methods: {
    promptForUpgrade() {
      this.showUpgradePrompt = true
    }
  }
}
</script>
<style scoped lang="sass">
.modal-card-foot
  display: flex
  justify-content: flex-end
.field
  position: relative
.prompt-hint
  position: absolute
  display: flex
  align-items: center
  justify-content: flex-start
  color: #777
.add-holding .field
  padding-bottom: 1.5rem
@media screen and (min-width: 769px)
  .add-holding .field
    padding-top: 1.65rem
    padding-bottom: 1.65rem
</style>
