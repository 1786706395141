<template lang="pug">
.loading-wrapper
  b-loading(:is-full-page="true" :active="isLoadingForm" :can-cancel="false")
  .entry-form(:class="{'is-new': mode === 'new'}" v-if="!isLoadingForm")
    .date-section
      .textual-datepicker
        b-datepicker(:value="entryForm.date" @input="onDateChange" :date-parser="dateParser" :date-formatter="dateFormatterLong" :max-date="today" v-if="mode == 'new'" ref="datepicker" :mobile-native="false")
        a(@click="(mode === 'new') && $refs.datepicker.toggle()") {{ dateFormatterLong(entryForm.date) }}
    b-message(title="Entry Exists" type="is-info" :closable="false" v-if="!isSaving && existingEntry")
      .content
        p You've already got an entry for {{ dateFormatterLong(entryForm.date) }}!
        p If you'd like to make a historical entry, you can click the date above to change it.
        p
          | Otherwise, you can&nbsp;
          strong
            router-link(:to="{name: 'edit_entry', params: { entryId: existingEntry.id }}") edit your existing entry here.
    h2.subtitle Assets (wealth)
    .asset-section(ref="assets")
      asset-input.wbox(v-for="(asset, index) in entryForm.assets" :key="asset.categoryId" :date="entryForm.date" v-bind.sync="asset" @remove="removeAsset(index)" @currency-updated="onCurrencyChange(asset)" ref='assetInputs')
      new-category-form.wbox(:form="assetCategoryForm" v-if="showNewAssetCategory" @cancel="showNewAssetCategory = false" @done="addAsset({ type: 'existing', id: $event.id })")
      add-holding-wrapper(v-else @on-add="addAsset" placeholder="Add an asset..." :existing-options="addableAssets" :new-options="assetClasses" :at-limit="tooManyHoldings")
    h2.subtitle Liabilities (debt)
    .liability-section(ref="liabilities")
      asset-input.wbox(v-for="(liability, index) in entryForm.liabilities" :key="liability.categoryId" :date="entryForm.date" v-bind.sync="liability" @remove="removeLiability(index)" @currency-updated="onCurrencyChange(liability)" ref='liabilityInputs')
      new-category-form.wbox(:form="liabilityCategoryForm" v-if="showNewLiabilityCategory" @cancel="showNewLiabilityCategory = false" @done="addLiability({ type: 'existing', id: $event.id })")
      add-holding-wrapper(v-else @on-add="addLiability" placeholder="Add a liability..." :existing-options="addableLiabilities" :new-options="liabilityClasses" :at-limit="tooManyHoldings")
    h2.subtitle Notes (optional)
    .notes
      textual-text-area(v-model="entryForm.note" placeholder="Did something interesting happen?")
    .submit-btn
      b-button.is-primary.is-fab(@click="saveEntry" :loading="isSaving" :disabled="saveDisabled" v-if="!(showNewLiabilityCategory || showNewAssetCategory)" title="Save Entry")
        | Save Entry
    .desktop-save-button
      b-button.is-primary(@click="saveEntry" :loading="isSaving" :disabled="saveDisabled" title="Save Entry")
        | Save Entry
</template>
<script>
import localforage from 'localforage'
import { filter, find, sortBy } from 'lodash'
import { mapState, mapGetters, mapActions } from 'vuex'
import dayjs from '@/dayjs'
import { assetClasses, liabilityClasses } from '@/misc/type_map'
import {
  dateParser,
  dateFormatterLong,
  currencyWithSubunit
} from '@/misc/helpers'
import { migrateHolding } from '@/store/entries/entries.migrations.js'
import AssetInput from '@/components/AssetInput'
import NewCategoryForm from '@/components/NewCategoryForm'
import AddHoldingWrapper from '@/components/AddHoldingWrapper'
import TextualTextArea from '@/components/textual/TextArea'

export default {
  components: {
    AssetInput,
    NewCategoryForm,
    AddHoldingWrapper,
    TextualTextArea
  },
  props: {
    mode: String,
    entry: Object,
    isSaving: Boolean,
    isDeleting: Boolean
  },
  data() {
    const today = dayjs().toDate()
    let entryForm
    if (this.mode === 'new') {
      entryForm = null
    } else {
      entryForm = this.$store.getters['entries/editEntryForm'](this.entry)
    }
    return {
      entryForm,
      today,
      exchangeRates: {},
      showNewAssetCategory: false,
      assetCategoryForm: null,
      showNewLiabilityCategory: false,
      liabilityCategoryForm: null,
      assetClasses,
      liabilityClasses,
      isLoadingForm: this.mode === 'new',
      isInitialBackup: true,
      assetInputFocused: false
    }
  },
  computed: {
    ...mapState('currencies', ['currencies', 'activeCurrency']),
    ...mapGetters('currencies', ['activeSubunit', 'activeCurrencyWithSubunit']),
    ...mapState('categories', ['categories']),
    ...mapGetters('authentication', ['isFreePlan', 'user']),
    tooManyHoldings() {
      const { isFreePlan } = this
      const { assets, liabilities } = this.entryForm
      return isFreePlan && assets.length + liabilities.length >= 8
    },
    existingEntry() {
      if (this.mode !== 'new') {
        return null
      }
      if (!this.entryForm) {
        return null
      }
      const { date } = this.entryForm
      return this.$store.getters['entries/getEntryById'](
        dayjs(date).format('YYYY-MM-DD')
      )
    },
    saveDisabled() {
      const {
        isDeleting,
        showNewLiabilityCategory,
        showNewAssetCategory,
        entryForm,
        existingEntry,
        isSaving
      } = this
      if (!entryForm) {
        return true
      }
      if (existingEntry && !isSaving) {
        return true
      }
      const { assets, liabilities } = this.entryForm
      if (isDeleting || showNewLiabilityCategory || showNewAssetCategory) {
        return true
      }
      if (assets.length) {
        return false
      }
      if (liabilities.length) {
        return false
      }
      return true
    },
    addableAssets() {
      if (!this.categories) return []
      const categories = filter(
        this.categories,
        category =>
          category.isAsset &&
          !find(
            this.entryForm.assets,
            asset => asset.categoryId === category.id
          )
      )
      return categories
    },
    addableLiabilities() {
      if (!this.categories) return []
      const categories = filter(
        this.categories,
        category =>
          !category.isAsset &&
          !find(
            this.entryForm.liabilities,
            liability => liability.categoryId === category.id
          )
      )
      return categories
    }
  },
  watch: {
    saveDisabled: {
      immediate: true,
      handler(disabled) {
        this.$emit('cansave', !disabled)
      }
    },
    entryForm: {
      deep: true,
      handler(form) {
        if (form && this.mode === 'new' && !this.isLoadingForm) {
          if (this.isInitialBackup) {
            this.isInitialBackup = false
            // Add focus listeners
            this.addFocusListeners()
            // Skip the first backup as it's an empty form
            return
          }
          this.saveBackup()
        }
      }
    }
  },
  mounted() {
    if (this.mode === 'new') {
      this.loadNewEntryForm()
    } else {
      this.addFocusListeners()
      this.refreshExchangeRates()
    }
  },
  beforeDestroy() {
    const { assets, liabilities } = this.$refs
    if (assets) {
      assets.removeEventListener('focusin', this.focusChanged)
      assets.removeEventListener('focusout', this.focusChanged)
    }
    if (liabilities) {
      liabilities.removeEventListener('focusin', this.focusChanged)
      liabilities.removeEventListener('focusout', this.focusChanged)
    }
  },
  methods: {
    ...mapActions('currencies', ['getExchangeRateOn']),
    addFocusListeners() {
      this.$nextTick(() => {
        this.$refs.assets.addEventListener('focusin', this.focusChanged)
        this.$refs.liabilities.addEventListener('focusin', this.focusChanged)
        this.$refs.assets.addEventListener('focusout', this.focusChanged)
        this.$refs.liabilities.addEventListener('focusout', this.focusChanged)
      })
    },
    focusChanged() {
      setTimeout(() => {
        if (document.hasFocus()) {
          const { activeElement } = document
          if (activeElement && activeElement.nodeName === 'INPUT') {
            if (
              activeElement.classList.contains('currency-input') ||
              activeElement.classList.contains('multiselect__input')
            ) {
              this.assetInputFocused = true
              return
            }
          }
        }
        this.assetInputFocused = false
      }, 150)
    },
    async loadNewEntryForm() {
      const newForm = this.$store.getters['entries/newEntryForm'](
        this.$route.query
      )
      const savedForm = await this.loadBackup()
      // If we have a form for today, automatically restore it
      if (savedForm && dayjs(newForm.date).isSame(savedForm.date)) {
        this.entryForm = savedForm
      } else if (savedForm) {
        this.entryForm = await this.maybeRestoreBackup(savedForm, newForm)
      } else {
        this.entryForm = newForm
      }
      this.refreshExchangeRates()
      this.isLoadingForm = false
    },
    maybeRestoreBackup(savedForm, newForm) {
      return new Promise(resolve => {
        this.$buefy.dialog.confirm({
          title: 'Restore draft?',
          message:
            "It looks like you started creating an entry but didn't finish it. Would you like to load that draft or start fresh? If you start fresh, your draft will be overwritten and can't be recovered.",
          cancelText: 'Start fresh',
          confirmText: 'Load draft',
          onConfirm() {
            resolve(savedForm)
          },
          onCancel() {
            resolve(newForm)
          }
        })
      })
    },
    saveBackup() {
      const form = {
        ...this.entryForm,
        userId: this.user.id
      }
      return localforage.setItem('entries/new-entry', form)
    },
    clearBackup() {
      return localforage.removeItem('entries/new-entry')
    },
    async loadBackup() {
      // TODO - move to vuex
      const savedForm = await localforage.getItem('entries/new-entry')
      if (!savedForm) {
        return null
      }
      // If the draft has a user ID and it doesn't match the current user,
      // we need to clear it. If it's blank we try restore it anyway for
      // backwards compatibility.
      if (savedForm.userId && savedForm.userId !== this.user.id) {
        await this.clearBackup()
        return null
      }
      const entryForm = {
        assets: [],
        liabilities: []
      }
      // Restore date
      entryForm.date = dayjs(savedForm.date)
        .startOf('day')
        .toDate()

      // If there's an existing entry for that date, we reset it to today to
      // avoid them getting stuck in the past
      if (
        this.$store.getters['entries/getEntryById'](
          dayjs(entryForm.date).format('YYYY-MM-DD')
        )
      ) {
        entryForm.date = dayjs()
          .startOf('day')
          .toDate()
      }

      // Restore note
      entryForm.note = savedForm.note || ''
      // Restore assets
      savedForm.assets.forEach(asset => {
        const category = this.$store.getters['categories/getCategoryById'](
          asset.categoryId
        )
        if (!category) {
          return
        }
        entryForm.assets.push(migrateHolding(asset))
      })
      // Restore liabilities
      savedForm.liabilities.forEach(liability => {
        const category = this.$store.getters['categories/getCategoryById'](
          liability.categoryId
        )
        if (!category) {
          return
        }
        entryForm.liabilities.push(migrateHolding(liability))
      })
      // Enforce sort order
      entryForm.assets = sortBy(savedForm.assets, ({ categoryId }) =>
        this.$store.getters['categories/categoryWeight'](categoryId)
      )
      entryForm.liabilities = sortBy(savedForm.liabilities, ({ categoryId }) =>
        this.$store.getters['categories/categoryWeight'](categoryId)
      )
      return entryForm
    },
    dateParser,
    dateFormatterLong,
    refreshExchangeRates() {
      const currencies = new Set()
      const date = this.dateParser(this.entryForm.date)
      const { exchangeRates } = this
      if (!(this.activeCurrencyWithSubunit in this.exchangeRates)) {
        currencies.add(this.activeCurrencyWithSubunit)
      }
      this.entryForm.assets.forEach(asset => {
        const withSubunit = currencyWithSubunit(asset.currency, asset.subunit)
        if (!(withSubunit in this.exchangeRates)) currencies.add(withSubunit)
      })
      this.entryForm.liabilities.forEach(liability => {
        const withSubunit = currencyWithSubunit(
          liability.currency,
          liability.subunit
        )
        if (!(withSubunit in this.exchangeRates)) currencies.add(withSubunit)
      })
      currencies.forEach(currency => {
        this.getExchangeRateOn({ currency, date })
          .then(rate => {
            exchangeRates[currency] = rate
            this.entryForm.assets.forEach(asset => {
              if (
                currencyWithSubunit(asset.currency, asset.subunit) === currency
              ) {
                asset.baseRate = rate
              }
            })
            this.entryForm.liabilities.forEach(liability => {
              if (
                currencyWithSubunit(liability.currency, liability.subunit) ===
                currency
              ) {
                liability.baseRate = rate
              }
            })
          })
          .catch(error => {
            if (error.message === 'no-rate') {
              this.entryForm.assets.forEach(asset => {
                if (
                  currencyWithSubunit(asset.currency, asset.subunit) ===
                  currency
                )
                  asset.customRate = true
              })
              this.entryForm.liabilities.forEach(liability => {
                if (
                  currencyWithSubunit(liability.currency, liability.subunit) ===
                  currency
                )
                  liability.customRate = true
              })
            } else {
              console.error(error)
            }
          })
      })
    },
    addAsset({ type, id }) {
      if (type === 'existing') {
        this.showNewAssetCategory = false
        this.assetCategoryForm = null
        const baseRate = this.exchangeRates[this.activeCurrencyWithSubunit]
        const category = this.$store.getters['categories/getCategoryById'](id)
        if (!category) {
          return
        }
        this.entryForm.assets.push({
          amount: 0,
          baseRate,
          categoryId: category.id,
          currency: this.activeCurrency.id,
          subunit: this.activeSubunit ? this.activeSubunit.id : null,
          type: category.type,
          customRate: false
        })
        if (baseRate === undefined) this.refreshExchangeRates()
      } else {
        this.showNewAssetCategory = true
        this.assetCategoryForm = this.$store.getters[
          'categories/newCategoryForm'
        ]({
          type: id,
          isAsset: true
        })
      }
      this.$nextTick(() => {
        if (type === 'existing') {
          const index = this.entryForm.assets.length - 1
          const input = this.$refs.assetInputs[index]
          input.focus()
        }
      })
    },
    addLiability({ type, id }) {
      if (type === 'existing') {
        this.showNewLiabilityCategory = false
        this.liabilityCategoryForm = null
        const baseRate = this.exchangeRates[this.activeCurrencyWithSubunit]
        const category = this.$store.getters['categories/getCategoryById'](id)
        if (!category) {
          return
        }
        this.entryForm.liabilities.push({
          amount: 0,
          baseRate,
          categoryId: category.id,
          currency: this.activeCurrency.id,
          subunit: this.activeSubunit ? this.activeSubunit.id : null,
          type: category.type,
          customRate: false
        })
        if (baseRate === undefined) this.refreshExchangeRates()
      } else {
        this.showNewLiabilityCategory = true
        this.liabilityCategoryForm = this.$store.getters[
          'categories/newCategoryForm'
        ]({
          type: id,
          isAsset: false
        })
      }
      this.$nextTick(() => {
        if (type === 'existing') {
          const index = this.entryForm.liabilities.length - 1
          const input = this.$refs.liabilityInputs[index]
          input.focus()
        }
      })
    },
    onDateChange(date) {
      this.entryForm.date = date
      this.exchangeRates = {}
      this.refreshExchangeRates()
    },
    onCurrencyChange(holding) {
      const baseRate = this.exchangeRates[
        currencyWithSubunit(holding.currency, holding.subunit)
      ]
      holding.baseRate = baseRate
      holding.customRate = false
      delete holding.lastAmount
      if (baseRate === undefined) this.refreshExchangeRates()
    },
    removeAsset(index) {
      this.entryForm.assets.splice(index, 1)
    },
    removeLiability(index) {
      this.entryForm.liabilities.splice(index, 1)
    },
    saveEntry() {
      this.$emit('onsave', this.entryForm)
    }
  }
}
</script>

<style lang="sass" scoped>
.entry-form
  margin-bottom: 6.5rem
.is-new .textual-datepicker a
  cursor: pointer
.textual-datepicker
  display: flex
  margin-top: 1rem
  margin-bottom: 2rem
  justify-content: center
  a
    cursor: not-allowed
    font-size: 1.4rem
    color: #444
    -webkit-tap-highlight-color: transparent !important
    border-bottom: 2px solid #9b9
  .datepicker ::v-deep input
    width: 0
    padding: 0
    margin: 0
    border: none
    box-shadow: none
h2.subtitle
  margin: 1rem
.wbox
  background: white
  padding: 0.75rem 1rem
  border-radius: 4px
  box-shadow: 0 1px 3px 0 #cfd7df
  margin-bottom: 0.5rem
  display: flex
  flex-direction: column
.notes
  padding: 0.5rem
.asset-section, .liability-section
  margin-bottom: 2rem
.multiselect
  margin-top: 1rem
.submit-btn
  padding: 0.5rem
  .is-fab
    width: 100%
    padding: 0.5rem
.add-holding ::v-deep select
  padding-left: 1rem
.desktop-save-button
  display: none
// Prevent the loading overlay from overlapping the dialog
.loading-overlay
  z-index: 40
@media screen and (min-width: 769px)
  .textual-datepicker
    justify-content: flex-start
    padding-left: 0.75rem
    margin-top: 0
    a
      font-size: 1.6rem
      padding-bottom: 0.25rem
  .asset-section, .liability-section
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr))
    grid-gap: 0.5rem
  .add-holding
    display: flex
    flex-direction: column
    justify-content: center
    background: white
    padding: 0.75rem 1rem
    border-radius: 4px
    box-shadow: 0 1px 3px 0 #cfd7df
    margin-bottom: 0.5rem
  .entry-form
    margin-bottom: 2rem
  .is-fab
    display: none
  .desktop-save-button
    display: block
    padding-top: 1.5rem
</style>
