<template lang="pug">
.new-category-form(:class='{asset: form.isAsset, liability: !form.isAsset}')
  .form-fields
    b-field(:label="form.isAsset ? 'Asset Name' : 'Liability Name'")
      b-input(type="text" v-model="form.name" required placeholder="Enter a name..." ref='nameField' @keydown.native.enter="save")
    b-field(label="Offset Account For" v-if="canHaveLoanAccount")
      b-select(v-model="form.offsetLoanId" placeholder="None")
        option(:value='null') None
        option(v-for="account in potentialLoanAccounts" :value="account.id") {{ account.name }}
    b-field(label="Offset Account" v-if="canHaveOffsetAccount")
      multi-select(:value="chosenOffsets" @input="updateOffsetIds" :multiple="true" :options="potentialOffsetAccounts" label="name" track-by="id" placeholder="Link offset(s)")
    b-field(label="Custom Asset Name" v-if="form.type == 'custom'" message="Used to group your custom asset types in charts")
      b-input(type="text" v-model="form.customName" required placeholder="Custom asset name..." ref='customNameField')
  b-message(title="Error" type="is-warning" aria-close-label="Close" v-if="errorMessage") {{ errorMessage }}
  .type-hint {{ activeClass.name }}
  .form-actions
    b-button(@click="cancel" :disabled="isSaving") Cancel
    b-button.is-primary(@click="save" :disabled="formInvalid" :loading="isSaving") Save
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { assetClasses, liabilityClasses } from '@/misc/type_map'
import offsetLinker from '@/mixins/offsetLinker'

export default {
  mixins: [offsetLinker],
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isSaving: false,
      errorMessage: null
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('categories', ['categories']),
    ...mapGetters('authentication', ['isFreePlan']),
    activeClass() {
      const { isAsset, type } = this.form
      if (isAsset) {
        return assetClasses.find(({ id }) => id === type)
      }
      return liabilityClasses.find(({ id }) => id === type)
    },
    formInvalid() {
      const { name, customName, type } = this.form
      if (!name.length) {
        return true
      }
      if (type === 'custom' && !customName.length) {
        return true
      }
      return false
    }
  },
  mounted() {
    this.$refs.nameField.focus()
  },
  methods: {
    ...mapActions('categories', ['saveCategory']),
    async save() {
      if (this.isSaving || this.formInvalid) return
      this.isSaving = true
      this.errorMessage = null
      try {
        const category = await this.saveCategory(this.form)
        this.$emit('done', category)
      } catch (error) {
        if (error.message === 'not-unique') {
          this.errorMessage =
            'You already have an asset or liability type named that. Please choose a new name'
          this.$refs.nameField.focus()
        } else {
          this.errorMessage = error.message
        }
      }
      this.isSaving = false
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="sass" scoped>
.form-actions
  margin-top: 0.75rem
  display: flex
  justify-content: space-between
  align-items: center
  .button
    width: 70px
.new-category-form
  position: relative
.type-hint
  font-size: 0.9rem
  position: absolute
  right: 1rem
  top: 0.85rem
.asset .type-hint
  color: #8a8
.liability .type-hint
  color: #b88
.message
  margin-top: 0.5rem
  margin-bottom: 0.5rem
</style>
