<template lang="pug">
.new-entry-screen.maybe-mobile-section
  .heading-area
    h1#entry-title New Entry
    a.button(v-if="!hasEntry" :disabled='true')
      b-icon.back-icon(icon="arrow-left")
      span.button-text Cancel
    router-link.button(v-if="hasEntry" :to="{name: 'entries'}")
      b-icon.back-icon(icon="arrow-left")
      span.button-text Cancel
    b-button.is-primary.save-button-top(@click="$refs.form.saveEntry()" :loading="loading" :disabled="saveDisabled") Save
  b-loading(:is-full-page="true" :active="initializing" :can-cancel="false")
  .content-area(v-if="!initializing")
    b-message(title="Error" type="is-warning" aria-close-label="Close" v-if="errorMessage") {{ errorMessage }}
    entry-form(mode="new" @onsave="saveEntry" :is-saving="loading" ref='form' @cansave="saveDisabled = !$event")
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import EntryForm from '@/components/EntryForm'

export default {
  components: {
    EntryForm
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      saveDisabled: false
    }
  },
  computed: {
    ...mapState('categories', ['loadingCategories']),
    ...mapState('currencies', ['loadingCurrencies']),
    ...mapState('entries', ['loadingEntries', 'entries']),
    ...mapGetters('authentication', ['isFreePlan']),
    ...mapGetters('entries', ['atFreePlanLimit']),
    initializing() {
      return (
        this.loadingCategories || this.loadingCurrencies || this.loadingEntries
      )
    },
    mustUpgrade() {
      return this.isFreePlan && this.atFreePlanLimit
    },
    hasEntry() {
      return this.entries && this.entries.length
    }
  },
  watch: {
    mustUpgrade: {
      immediate: true,
      handler(mustUpgrade) {
        if (mustUpgrade) {
          this.$router.push({
            name: 'billing_plan',
            query: {
              action: 'at_entry_limit'
            }
          })
        }
      }
    }
  },
  methods: {
    ...mapActions('entries', ['addEntry']),
    saveEntry(entryForm) {
      if (this.loading) {
        return
      }
      this.loading = true
      this.errorMessage = null
      this.addEntry(entryForm)
        .then(({ id }) => {
          this.$refs.form.clearBackup()
          this.$router.push({ name: 'entries', query: { date: id } })
          this.$buefy.toast.open('Entry added!')
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.errorMessage = error.message
          this.$scrollTo(document.getElementById('entry-title'))
        })
    }
  },
  metaInfo: {
    title: 'New Entry'
  }
}
</script>
<style lang="sass" scoped>
.maybe-mobile-section
  display: flex
  flex-direction: column
  flex-grow: 1
  background: #fafafa
  padding: 0.5rem
.heading-area
  display: flex
  align-items: center
  margin-bottom: 1.5rem
.content-area
  flex-grow: 1
#entry-title
  order: 2
  text-align: center
  flex-grow: 1
  font-weight: bold
  font-size: 1.1rem
  padding-right: 2.5rem
.button-text
  display: none
  order: 1
.button
  display: flex
  align-items: center
  justify-content: center
  height: 2.5rem
  width: 2.5rem
  .back-icon
    margin: 0 !important
.save-button-top
  display: none
@media screen and (min-width: 769px)
  .maybe-mobile-section
    align-items: center
  .heading-area, .content-area
    width: 100%
    max-width: 1300px
  .button
    width: 5rem
    order: 2
  .button-text
    display: block
  .back-icon
    display: none
  .heading-area
    margin-top: 1.5rem
    padding: 0 0.5rem 0 0.75rem
  #entry-title
    font-size: 1.4rem
    font-weight: normal
    order: 1
    text-align: left
  .save-button-top
    display: block
    order: 3
    margin-left: 0.75rem
</style>
