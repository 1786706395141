<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title Choose a Unit
    b-button(icon-left="times" @click="$parent.close()")
  section.modal-card-body
    b-radio(v-model="newValue" :native-value="subunit.id" v-for="subunit in orderedSubunits" :key="subunit.id")
      | {{ subunit.description }}
    b-checkbox(v-model="convert" v-if="showConvert && amount && newValue !== value")
      | Convert
      | {{ amount | currencyFormatter(oldCurrencyWithSubunit) }}
      | to
      | {{ convertedAmount | currencyFormatter(newCurrencyWithSubunit) }}?
  footer.modal-card-foot
    b-button.is-primary(@click="done") Done
</template>
<script>
import { sortBy } from 'lodash'
import { currencyWithSubunit } from '@/misc/helpers'
import currencyFormatter from '@/misc/currencyFormatter'
import CurrencyOutput from '@/components/CurrencyOutput'

export default {
  components: {
    CurrencyOutput
  },
  filters: {
    currencyFormatter
  },
  props: {
    value: String, // subunit ID
    currency: String, // currency ID
    amount: Number,
    showConvert: Boolean
  },
  data() {
    return {
      newValue: '',
      convert: true
    }
  },
  computed: {
    chosenCurrency() {
      return this.$store.getters['currencies/getCurrencyById'](this.currency)
    },
    subunits() {
      const { chosenCurrency } = this
      if (!this.chosenCurrency) {
        return []
      }
      const { subunits = {} } = chosenCurrency
      return subunits
    },
    orderedSubunits() {
      if (!this.subunits) {
        return []
      }
      return sortBy(Object.values(this.subunits), 'order')
    },
    convertedAmount() {
      const convert = this.$store.getters['currencies/convert']
      const convertToBase = this.$store.getters['currencies/convertToBase']
      const { amount, currency, value, newValue } = this
      return convert(convertToBase(amount, currency, value), currency, newValue)
    },
    oldCurrencyWithSubunit() {
      const { currency, value } = this
      return currencyWithSubunit(currency, value)
    },
    newCurrencyWithSubunit() {
      const { currency, newValue } = this
      return currencyWithSubunit(currency, newValue)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.newValue = value
      }
    }
  },
  methods: {
    done() {
      const { newValue, value, convert, showConvert } = this
      if (newValue !== value) {
        this.$emit('onchange', {
          subunit: this.newValue,
          convert: showConvert && convert
        })
      }
      this.$parent.close()
    }
  }
}
</script>
<style lang="sass" scoped>
.message
  width: 100%
.modal-card-foot
  display: flex
.modal-card-body
  display: flex
  flex-direction: column
.b-radio, .b-checkbox
  margin: .5rem 0
.spacer
  flex-grow: 1
</style>
